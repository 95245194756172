export default {
  SET_LINKS: (state, payload) => {
    Object.assign(state.links, { ...payload });
  },
  SET_FORM_FLOWS: (state, payload) => {
    state.formFlows = payload;
  },
  SET_FORM_FLOW: (state, payload) => {
    state.formFlow = payload;
  },
  SET_SELECTED_FORM_FLOW: (state, payload) => {
    state.selectedFormFlow = payload;
  },
  SET_DATA_MAPPINGS: (state, payload) => {
    state.dataMappings = payload;
  },
  SET_PAGINATION: (state, payload) => {
    state.pagination = payload;
  },
  SET_LOADING: (state, payload) => {
    state.loading = payload;
  },
  SET_FLOW_PERMISSIONS: (state, payload) => {
    state.flowPermissions = payload;
  },
  SET_HAS_PENDING_PROCESSES: (state, payload) => {
    state.hasPendingProcesses = payload;
  },
  SET_HAS_WAITING_SECOND_FORM_PROCESSES: (state, payload) => {
    state.hasWaitingSecondFormProcesses = payload;
  },
  SET_TOTAL_ITEMS: (state, payload) => {
    state.totalItems = payload;
  },
  SET_FLOWS_LINKS: (state, payload) => {
    state.flowsLinks = payload;
  },
  SET_MOVE_FOLDERS: (state, payload) => {
    state.moveFolders = payload;
  },
  SET_MOVE_FOLDERS_PAGINATION: (state, payload) => {
    state.moveFoldersPagination = payload;
  },
};
