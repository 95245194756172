import { axios } from '@/plugins';

const fetchFormFlows = async ({ getters, commit, rootGetters }, params) => {
  // TOGGLE_NEW_LISTING_FLOW_ENABLED
  // TODO: remove isToggleEnabled
  const isToggleEnabled = rootGetters['featureFlags/isToggleEnabled'];
  // TOGGLE_NEW_LISTING_FLOW_ENABLED
  // TODO: set perPage = 10
  const perPage = isToggleEnabled('toggleNewListingFlowEnabled') ? 10 : 30;

  try {
    commit('SET_LOADING', true);
    const { data } = await axios.get(getters.getLinks.self, {
      params: {
        perPage,
        ...params,
      },
    });

    commit('SET_FORM_FLOWS', data.flows);
    commit('SET_PAGINATION', data.pagination);
    commit('SET_TOTAL_ITEMS', data.totalItems);
    commit('SET_FLOWS_LINKS', data.links);
    commit('SET_LOADING', false);
  } catch (err) {
    throw err;
  }
};

const createFormFlow = async ({ rootGetters }, payload = {}) => {
  try {
    const response = await axios.post(rootGetters['info/getLinks'].flows, payload);

    return response;
  } catch (err) {
    throw err;
  }
};

const duplicateFormFlow = async ({ state, dispatch }) => {
  const url = state.selectedFormFlow.links.duplicate;
  await axios.post(url);

  dispatch('fetchFormFlows');
};

const getFormFlowPermissions = async ({ state, commit }) => {
  try {
    commit('SET_LOADING', true);
    const {
      data: { flow },
    } = await axios.get(state.selectedFormFlow.links.permissions);
    commit('SET_LOADING', false);
    commit('SET_FLOW_PERMISSIONS', flow);
  } catch (error) {
    throw error;
  }
};

const setFormFlowPrivacy = async ({ dispatch, state }, payload) => {
  try {
    const url = state.selectedFormFlow.links.privacy;
    await axios.patch(url, payload);
    dispatch('fetchFormFlows');
  } catch (error) {
    throw error;
  }
};

const disableFormFlow = async ({ dispatch, state }) => {
  try {
    const url = state.selectedFormFlow.links.disable;

    await axios.put(url, {});
    dispatch('fetchFormFlows');
  } catch (error) {
    throw error;
  }
};

const deleteFormFlow = async ({ dispatch, state }) => {
  try {
    const response = await axios.delete(state.selectedFormFlow.links.self);
    dispatch('fetchFormFlows');
    return response;
  } catch (error) {
    throw error;
  }
};

const removeFromFolder = async ({ state, dispatch }, { url }) => {
  const { id } = state.selectedFormFlow;

  await axios.patch(url, { flowFormIds: [id] });

  dispatch('fetchFormFlows');
};

const setSelectedFormFlow = async ({ commit }, flow) => {
  commit('SET_SELECTED_FORM_FLOW', flow);
};

const resetSelectedFormFlow = async ({ commit }) => {
  commit('SET_SELECTED_FORM_FLOW', {});
};

const fetchFormFlowData = async ({ getters, commit }) => {
  try {
    const { self } = getters.getLinks;
    const { data } = await axios.get(self);

    commit('SET_LINKS', data.links);
    commit('SET_FORM_FLOW', data.flow);

    return data;
  } catch (error) {
    throw error;
  }
};

const fetchFormFlow = async ({ commit }, { link }) => {
  const response = await axios.get(link);
  commit('SET_FORM_FLOW', response.data.flow);
};

const fetchFormFlowDataMappings = async ({ getters, commit }) => {
  const {
    data: { dataMappings },
  } = await axios.get(getters.getFormFlow.links.dataMappings);
  commit('SET_DATA_MAPPINGS', dataMappings);
};

const verifyPendingProcesses = async ({ getters, commit }) => {
  const link = getters.getSelectedFlow.links.verifyPendingProcesses;
  const {
    data: { hasPendingProcesses, hasWaitingSecondFormProcesses },
  } = await axios.get(link);

  commit('SET_HAS_PENDING_PROCESSES', hasPendingProcesses);
  commit('SET_HAS_WAITING_SECOND_FORM_PROCESSES', hasWaitingSecondFormProcesses);
};

const saveConfigFormFlow = async ({ commit }, { url, payload }) => {
  const {
    data: { flow },
  } = await axios.patch(url, payload);
  commit('SET_FORM_FLOW', flow);
};

const saveSecondFormConfig = async (_, payload) => {
  const { accountId, flowId, dataSourceId } = payload;
  const url = `/accounts/${accountId}/flows/${flowId}/data_sources/${dataSourceId}`;
  await axios.patch(url, payload);
};

const fetchMoveFolders = async ({ getters, commit }, name) => {
  const url = getters.getFlowsLinks.filterFolders;
  const nextPage = (getters.getMoveFoldersPagination.page ?? 0) + 1;
  const params = {
    page: nextPage,
    name,
  };
  const {
    data: {
      filters: { folders, pagination },
    },
  } = await axios.get(url, { params });

  commit('SET_MOVE_FOLDERS', [...getters.getMoveFolders, ...folders]);
  commit('SET_MOVE_FOLDERS_PAGINATION', pagination);
};

const resetMoveFolders = ({ commit }) => {
  commit('SET_MOVE_FOLDERS', []);
  commit('SET_MOVE_FOLDERS_PAGINATION', {});
};

export default {
  fetchFormFlows,
  createFormFlow,
  duplicateFormFlow,
  getFormFlowPermissions,
  disableFormFlow,
  deleteFormFlow,
  setFormFlowPrivacy,
  setSelectedFormFlow,
  resetSelectedFormFlow,
  fetchFormFlowData,
  fetchFormFlow,
  fetchFormFlowDataMappings,
  verifyPendingProcesses,
  saveConfigFormFlow,
  saveSecondFormConfig,
  removeFromFolder,
  fetchMoveFolders,
  resetMoveFolders,
};
