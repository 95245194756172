const flowModule = {
  title: 'Flujos con formulario',
  new: 'Crear flujo',
  header: {
    filter: {
      label: 'Nombre del flujo',
      placeholder: 'Buscar un flujo',
      clear: 'Limpiar',
      button: 'Filtrar',
    },
  },
  list: {
    loading: 'Cargando la lista de flujos...',
    empty: 'Crea flujos vinculando formularios a plantillas y automatiza el proceso de firma.',
    usedTemplate: 'Plantilla utilizada:',
    usedForm: 'Formularios utilizados:',
    undefined: 'No definido',
    enabled: 'Activo',
    disabled: 'Inactivo',
    flowInfo: 'Ver información del flujo',
    private: 'Privado',
    flowLength: '{count} Flujo | {count} Flujos',
    tab: {
      flow: 'Flujos',
      folders: 'Carpetas',
    },
    actions: {
      copyLink: 'Copiar enlace',
      copyFormLink: 'Copiar enlace del formulario',
      options: 'Opciones',
      editLink: 'Editar para activar',
      privacy: 'Permisos',
      disableToEdit: 'Desactivar para editar',
      removeFromFolder: 'Eliminar flujo de la carpeta',
      info: 'Información del flujo',
    },
    notifications: {
      disable: '¡Flujo desactivado con éxito!',
      delete: '¡Flujo activado con éxito!',
      duplicated: '¡Flujo duplicado con éxito!',
      removedFromFolder: '¡Flujo eliminado de la carpeta con éxito!',
      failedToRemoveFromFolder: 'No se pudo eliminar de la carpeta',
    },
    table: {
      name: 'Nombre',
      folder: 'Carpeta',
      createdAt: 'Fecha de Creación',
      enabled: 'Estado',
      actions: 'Acciones',
      collapse: {
        template: 'Modelo utilizado',
        dataSource: 'Formulario {0} utilizado',
      },
    },
  },
  folders: {
    table: {
      name: 'Nombre',
      flowNumber: 'Número de flujos',
      createdAt: 'Fecha de Creación',
      actions: 'Acciones',
      newFolder: 'Nueva carpeta',
      folderLength: '{count} Carpeta | {count} Carpetas',
    },
    actions: {
      rename: 'Renombrar',
      delete: 'Eliminar',
    },
    emptyList: {
      title: 'Aún no tienes ninguna carpeta',
      description: 'Cuando crees tu carpeta, aparecerá aquí junto con las demás.',
      actionText: 'Nueva carpeta',
    },
    emptyFlowList: {
      title: 'Tu carpeta está vacía',
      description:
        'Puedes crear un nuevo flujo directamente dentro de esta carpeta o buscar un flujo ya existente.',
      searchExistingFlows: 'Buscar flujo existente',
    },
    create: {
      modal: {
        placeholder: 'Nombre de la carpeta',
        title: 'Nueva carpeta',
        selectedFlows:
          'Cuando crea una nueva carpeta con transmisiones preseleccionadas, se moverán automáticamente a ella.',
      },
      errorMessage: 'No se pudo crear la carpeta',
      successMessage: 'Carpeta creada con éxito',
    },
    searchExistingFlows: {
      modal: {
        title: 'Mover flujos a la carpeta',
        description: 'Seleccione o busque qué flujos se moverán a esta carpeta.',
        goToFlows: 'Ir a Flujos',
        move: 'Mover | Mover {count} flujo | Mover {count} flujos',
        empty: 'Ningún resultado encontrado',
        placeholder: 'Busque un flujo',
      },
      errorMessage: 'No fue posible mover los flujos',
      successMessage: '¡Flujos movidos con éxito!',
    },
    moveFlowsToFolder: {
      modal: {
        title: 'Mover flujos',
        description: 'Seleccione la carpeta de destino para mover el flujo.',
        move: 'Mover',
        empty: 'No se encontraron resultados',
        placeholder: 'Busca una carpeta',
        newFolder: 'Nueva carpeta',
        emptyFolder:
          'Aún no tienes ninguna carpeta creada para mover tus transmisiones. Crea uno haciendo clic en el botón de abajo.',
      },
      errorMessage: 'No se pudo mover los flujos',
      successMessage: '¡Flujos movidos con éxito!',
    },
    rename: {
      modal: {
        title: 'Renombrar carpeta',
        placeholder: 'Nombre de la carpeta',
      },
      errorMessage: 'No se pudo renombrar la carpeta',
      successMessage: 'Carpeta renombrada con éxito',
    },
    delete: {
      title: '¿Eliminar carpeta?',
      description: 'Los flujos dentro de esta carpeta volverán a la pestaña de Flujos generales.',
      errorMessage: 'No se pudo eliminar la carpeta',
      successMessage: 'Carpeta eliminada con éxito',
    },
    error: {
      fetch: 'Ocurrió un error al buscar los flujos. Intenta nuevamente',
    },
  },
  filters: {
    activator: 'Filtros | Filtros ({count}) | Filtros ({count})',
    apply: 'Aplicar',
    clear: 'Limpiar filtros',
    dateRangeLabel: 'Período',
    dateRangePlaceholder: 'Todo',
    search: 'Buscar un flujo',
    statusLabel: 'Status',
    status: {
      all: 'Todo',
      disabled: 'Inactivo',
      enabled: 'Activo',
    },
    title: 'Filtrar flujos',
  },
  modalAlert: {
    header: 'Atención',
    title: 'Desactiva los flujos conectados',
    description: 'Para editar o eliminar, desactiva los {0} conectados a este elemento.',
    flows: 'flujos',
  },
  modalDelete: {
    header: 'Eliminar elemento',
    title: '¿Estás seguro de que deseas eliminar este elemento?',
    hasPendingProcesses:
      'Atención: Al eliminar la plantilla, los datos en proceso de aprobación se perderán. ¿Deseas continuar?',
  },
  modalFormFlowDelete: {
    header: '¿Eliminar flujo?',
    title:
      'El informe y las aprobaciones pendientes de este flujo también serán eliminados. Para guardar una copia de las respuestas del flujo, ingresa al informe y descárgalo antes de eliminarlo',
  },
  modalDisable: {
    header: 'Desactivar flujo',
    title:
      'Si desactivas el flujo, no podrá ser utilizado, pero podrás activarlo en cualquier momento.',
    hasPendingProcesses: {
      header: 'Flujo en proceso de aprobación',
      title:
        'Este flujo contiene respuestas pendientes de aprobación. Aprueba o rechaza las respuestas de este flujo antes de desactivarlo.',
      action: 'Ir al flujo en aprobación',
    },
    hasWaitingSecondFormProcesses: {
      header: 'Este flujo tiene respuestas pendientes',
      title:
        'Este flujo tiene respuestas pendientes segundo formulario. Aprueba o rechaza las respuestas de este flujo antes de desactivarlo.',
      action: 'Ir para respuestas pendentes',
    },
  },
  modalPrivacy: {
    title: 'Definir permisos de acceso del flujo',
    selectAccessType: {
      label: 'Selecciona el tipo de acceso del flujo',
      public: 'Acceso para todos los usuarios de la cuenta',
      private: 'Acceso solo para usuarios seleccionados',
    },
    selectMembers: {
      label: 'Selecciona los usuarios que tendrán acceso al flujo',
      admin: 'Administrador',
      adminInfo:
        'Por defecto, los administradores de la cuenta tendrán acceso a todas las carpetas',
      operator: 'Operador del flujo',
      operatorInfo:
        'Este usuario no puede ser eliminado ya que está vinculado como operador del flujo',
    },
    notification: {
      updated: 'Permisos de acceso actualizados con éxito.',
      error: 'No fue posible actualizar los permisos de acceso',
    },
  },
  modalCopyLink: {
    title: 'Copiar enlace',
    description:
      'Envía este enlace a la persona que debe completar el formulario. Una vez completado, se generará y se enviará automáticamente un nuevo documento para la firma.',
    copy: 'Copiar',
    popper: '¡Copiado!',
    popperError: 'No se pudo copiar, por favor copia manualmente.',
    link: 'No se pudo copiar, por favor copia manualmente',
    close: 'Cerrar',
  },
  setup: {
    loading: 'Cargando información del flujo...',
  },
  modalEdit: {
    header: 'Editar elemento',
    title: '¿Estás seguro de que deseas editar este elemento??',
    hasPendingProcesses:
      'Atención: Al editar la plantilla, los datos en proceso de aprobación se perderán. ¿Deseas continuar?',
  },
  modalEditEnabled: {
    header: '¿Editar flujo?',
    title: 'Para editar este flujo será necesario desactivarlo. ¿Deseas continuar con la edición?',
    action: 'Desactivar para editar',
    errorMessage: 'Ocurrió un error al desactivar el flujo',
  },
  show: {
    enabled: 'Habilitado',
    disabled: 'Desabilitado',
    activated: 'Activado',
    deactivated: 'Desactivado',
    disableForEdit: 'Desastivar para editar',
    editToActivate: 'Editar para activar',
    ordernationEnabled: 'Clasificación activada',
    ordernationDisabled: 'Clasificación desactivada',
    message: 'Mensaje',
    modelField: 'Campo de texto: ',
    formField: 'Campo del formulario: ',
    generalInfo: {
      firstForm: 'Formulario 1',
      secondForm: 'Formulario 2',
      approvation: 'Aprobación',
      secondFormEmail: 'Correo electrónico del segundo formulario',
      template: 'Plantilla',
      membershipName: 'Operador del flujo',
      flowMemberships: 'Responsables de la aprobación',
    },
    signerInfo: {
      title: 'Firmantes',
      email: 'Correo electrónico',
      name: 'Nombre completo',
      foreignerSigner: 'Firmante extranjero',
      signer: 'Firmante',
      phoneNumber: 'Número do celular',
      birthday: 'Fecha de nacimiento',
      send: 'Envio',
      auth: 'Autenticaciones',
      flowSigner: 'Del formulario',
      fixed: 'Fijo',
      refusable: 'Firma rechazada',
    },
    storageFile: {
      title: 'Almacenamiento de archivos',
      documentName: 'Nombre del documento',
      storage: 'Carpeta donde será almacenado',
    },
    configInfo: {
      title: 'Configuraciones',
      deadlineIn: 'Fecha límite de firma',
      locale: 'Idioma',
      remindInterval: 'Recordatorios automáticos',
      autoClose: 'Finalización del  documento',
    },
    tabs: {
      config: 'Configuraciones',
      fields: 'Campos de la plantilla y formulario',
    },
  },
  emptyList: {
    title: 'Crea tu primer flujo con formulario',
    description: 'Una vez que crees un flujo, se listará aquí junto con los demás flujos creados.',
  },
  emptySearch: {
    title: 'No se encontraron resultados',
    description: 'Intenta cambiar tu búsqueda o filtro para encontrar lo que estás buscando.',
    button: 'Mostrar todos los flujos',
    folderButton: 'Mostrar todas las carpetas',
  },
  bulkActions: {
    totalSelected: '{count} elemento seleccionado | {count} elementos seleccionados',
  },
};

export default flowModule;
