<script setup>
import { useI18n } from '@/locales';
import { useStore } from '@/store';
import { useRoute, useRouter, useToggle } from '@base/hooks';
import { camelizeKeys } from '@base/utils';
import {
  FormFlowListFilters,
  FormFlowEmptyList,
  FormFlowEmptySearch,
  FormFlowTableItem,
  FormFlowListActions,
} from '@flow/components/form-flow';
import { useFormFlowListActions } from '@flow/composables/useFormFlowListActions';
import { useFormFlowListFilters } from '@flow/composables';
import { computed, onBeforeMount, watch } from 'vue';
import {
  FLOW_CONTEXT_NAMES_CONSTANTS,
  FORM_FLOW_ACTIONS_CONSTANTS,
  FORM_FLOW_MODAL_CONSTANTS,
} from '@flow/constants';

const props = defineProps({
  context: {
    type: String,
    default: FLOW_CONTEXT_NAMES_CONSTANTS.flowList,
  },
});

const { t } = useI18n();
const store = useStore();
const route = useRoute();
const router = useRouter();
const { hasFilters } = useFormFlowListFilters();
// TOGGLE_BULK_ACTIONS_ON_FLOW_FORM_ENABLED
// TODO: Remove toggleBulkActionsOnFlowFormEnabled
const { isEnabled: toggleBulkActionsOnFlowFormEnabled } = useToggle(
  'toggle_bulk_actions_on_flow_form_enabled'
);

const {
  selectedRows,
  tableEl,
  modalShowed,
  handleNew,
  handleCloseModal,
  handleDelete,
  handleDisable,
  handleOpenModal,
  handleClickOption,
  handleMove,
  handleNewFolder,
  handleCreateFolder,
  resetSelectedRows,
} = useFormFlowListActions();

let HEADERS = [
  {
    label: t('flowModule.list.table.name'),
    value: 'name',
    colspan: 4,
    sortable: true,
  },
  ...(props.context === FLOW_CONTEXT_NAMES_CONSTANTS.flowList
    ? [
        {
          label: t('flowModule.list.table.folder'),
          value: 'folder',
          colspan: 4,
          sortable: true,
        },
      ]
    : []),
  {
    label: t('flowModule.list.table.createdAt'),
    value: 'createdAt',
    sortable: true,
  },
  {
    label: t('flowModule.list.table.enabled'),
    value: 'enabled',
    sortable: true,
  },
  {
    label: t('flowModule.list.table.action'),
    value: 'action',
    colspan: 2,
  },
];

const loading = computed(() => store.getters['formFlow/getLoading']);
const formFlows = computed(() => store.getters['formFlow/getFormFlows']);
const totalItems = computed(() => store.getters['formFlow/getTotalItems']);
const pagination = computed(() => store.getters['formFlow/getPagination']);
const account = computed(() => store.getters['info/getAccount']);
const disabledActions = computed(() =>
  props.context === FLOW_CONTEXT_NAMES_CONSTANTS.flowList
    ? [FORM_FLOW_ACTIONS_CONSTANTS.removeFromFolder]
    : []
);
const isSelectedAll = computed(
  () => tableEl.value?._setupProxy?.selectedRows?.length === formFlows.value.length
);
const showBulkActions = computed(() => selectedRows.value.length > 0);

const ORDER_FIELDS_MAPPING = {
  name: 'name',
  folder: 'folder.name',
  createdAt: 'created_at',
  enabled: 'enabled',
};

const handleSorted = async (order, column) => {
  let orderDirection;

  if (ORDER_FIELDS_MAPPING[column.value] === route.query.orderBy) {
    orderDirection = route.query.orderDirection === 'asc' ? 'desc' : 'asc';
  }

  await router.replace({
    query: {
      ...camelizeKeys(route.query),
      orderBy: ORDER_FIELDS_MAPPING[column.value],
      orderDirection: orderDirection || order,
    },
  });
};

onBeforeMount(() => {
  if (props.context === FLOW_CONTEXT_NAMES_CONSTANTS.folder) {
    HEADERS = HEADERS.filter((item) => item.value !== 'folder');
  }
});

watch(isSelectedAll, () => {
  selectedRows.value = isSelectedAll.value ? formFlows.value : [];
});
</script>

<template>
  <div>
    <XCard>
      <XCard
        v-if="showBulkActions"
        :class="$style.bulkActions"
        data-testid="formFlowBulkActions"
        padding="var(--space-small-x) var(--space-regular)"
      >
        <div :class="$style.cancelWrapper">
          <XButton
            model="plain"
            @click="resetSelectedRows"
          >
            <XIcon icon="close" />
          </XButton>
          <span :class="$style.totalSelected">
            {{
              $tc('flowModule.bulkActions.totalSelected', selectedRows.length, {
                count: selectedRows.length,
              })
            }}
          </span>
        </div>
        <XButton
          model="plain"
          @click="handleOpenModal(FORM_FLOW_MODAL_CONSTANTS.moveFlowsToFolderModal)"
        >
          <XIcon
            icon="arrow-alt-right"
            left
          />
          {{ $t('general.action.move') }}
        </XButton>
      </XCard>
      <div
        v-if="totalItems != 0 || hasFilters"
        :class="$style.tableActions"
        data-testid="tableActions"
      >
        <FormFlowListFilters :total-items="totalItems" />
      </div>
      <template v-if="totalItems == 0 && !loading">
        <FormFlowEmptyList
          v-if="!hasFilters"
          icon="code-branch"
          :title="$t('flowModule.emptyList.title')"
          :description="$t('flowModule.emptyList.description')"
          :action-text="$t('flowModule.new')"
          data-testid="formFlowEmptyList"
          @handle-new="handleNew"
        />
        <FormFlowEmptySearch
          v-else
          :button-text="$t('flowModule.emptySearch.button')"
        />
      </template>
      <template v-else>
        <!-- TOGGLE_BULK_ACTIONS_ON_FLOW_FORM_ENABLED -->
        <!-- TODO: Set :checkable="true" -->
        <XTable
          ref="tableEl"
          :headers="HEADERS"
          :items="formFlows"
          :loading="loading"
          :checkable="toggleBulkActionsOnFlowFormEnabled"
          data-testid="formFlowList"
          @sort="handleSorted"
        >
          <template #item="{ item, index, headers, gridTemplateColumns }">
            <!-- TOGGLE_BULK_ACTIONS_ON_FLOW_FORM_ENABLED -->
            <!-- TODO: Set :checkable="true" -->
            <FormFlowTableItem
              :item="item"
              :headers="headers"
              :grid-template-columns="gridTemplateColumns"
              :index="index"
              :account="account"
              :loading="loading"
              :disabled-actions="disabledActions"
              :context="context"
              :checkable="toggleBulkActionsOnFlowFormEnabled"
              :selected-rows.sync="selectedRows"
              @open-modal="handleOpenModal"
              @click-option="handleClickOption"
            />
          </template>
        </XTable>
      </template>
      <XPagination
        v-if="pagination?.pageCount > 1"
        :class="$style.containerPagination"
        align="center"
        :active-page="pagination?.page"
        :total-pages="pagination?.pageCount"
        :next-text="$t('general.action.next')"
        :prev-text="$t('general.action.previous')"
        data-testid="paginationContainer"
      />
    </XCard>
    <FormFlowListActions
      :modal-showed="modalShowed"
      data-testid="formFlowListActions"
      @close="handleCloseModal"
      @disable="handleDisable"
      @delete="handleDelete"
      @handle-move="($e) => handleMove($e)"
      @new-folder="handleNewFolder"
      @create-folder="($e) => handleCreateFolder($e)"
    />
  </div>
</template>

<style lang="scss" module>
.containerPagination {
  margin-top: var(--space-regular);
}

.tableActions {
  width: 100%;
  margin-bottom: var(--space-medium);
}

.bulkActions {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: var(--space-regular);
  gap: var(--space-medium);
  background-color: var(--color-neutral-50);

  .cancelWrapper {
    display: flex;
    gap: var(--space-small-x);

    .totalSelected {
      font-size: var(--font-size-small);
    }
  }
}
</style>
