import { useI18n } from '@/locales';
import { useStore } from '@/store';
import { useRouter, useToast, useValidateAccountActions } from '@base/hooks';
import { FORM_FLOW_MODAL_CONSTANTS, FORM_FLOW_ACTIONS_CONSTANTS } from '@flow/constants';
import { computed, nextTick, ref } from 'vue';

export function useFormFlowListActions() {
  const { validateAccountActions } = useValidateAccountActions();
  const router = useRouter();
  const store = useStore();
  const toast = useToast();
  const { t } = useI18n();
  const folderLinks = computed(() => store.getters['flowFolder/getFolder']?.links ?? {});

  const modalShowed = ref(null);
  const selectedRows = ref([]);
  const tableEl = ref();

  const handleEdit = (flow) => {
    if (validateAccountActions()) {
      router.push({ path: flow.links.edit });
    }
  };

  const handleNew = async (folderId = null) => {
    if (validateAccountActions()) {
      const { data } = await store.dispatch('formFlow/createFormFlow', { folderId });
      handleEdit(data.flow);
    }
  };

  const handleSuccessMessage = (type) => {
    const message = t(`flowModule.list.notifications.${type}`);
    toast.success(message);
  };

  const handleCloseModal = () => {
    store.dispatch('formFlow/resetSelectedFormFlow');
    modalShowed.value = null;
  };

  const handleDelete = async () => {
    await store.dispatch('formFlow/deleteFormFlow');
    handleSuccessMessage('delete');
    handleCloseModal();
  };

  const handleDisable = async () => {
    await store.dispatch('formFlow/disableFormFlow');
    handleSuccessMessage('disable');
    handleCloseModal();
  };

  const handleOpenModal = async (modal, flow = null) => {
    if (flow) {
      store.dispatch('formFlow/setSelectedFormFlow', flow);
    }
    modalShowed.value = modal;
  };

  const handleDuplicate = async (flow) => {
    if (flow) store.dispatch('formFlow/setSelectedFormFlow', flow);
    await store.dispatch('formFlow/duplicateFormFlow');
    toast.success(t('flowModule.list.notifications.duplicated'));
  };

  const handleRemoveFromFolder = async (flow) => {
    if (flow) store.dispatch('formFlow/setSelectedFormFlow', flow);

    try {
      await store.dispatch('formFlow/removeFromFolder', { url: folderLinks.value.removeForms });
      toast.success(t('flowModule.list.notifications.removedFromFolder'));
    } catch {
      toast.error(t('flowModule.list.notifications.failedToRemoveFromFolder'));
    }
  };

  const handleClickOption = ({ option, flow }) => {
    switch (option) {
      case FORM_FLOW_ACTIONS_CONSTANTS.edit:
        if (flow.enabled) {
          handleOpenModal(FORM_FLOW_MODAL_CONSTANTS.formFlowEditEnabledModal, flow);
        } else {
          handleEdit(flow);
        }
        break;
      case FORM_FLOW_ACTIONS_CONSTANTS.copyFormLink:
        handleOpenModal(FORM_FLOW_MODAL_CONSTANTS.formFlowCopyLinkModal, flow);
        break;
      case FORM_FLOW_ACTIONS_CONSTANTS.disable:
        handleOpenModal(FORM_FLOW_MODAL_CONSTANTS.formFlowDisableModal, flow);
        break;
      case FORM_FLOW_ACTIONS_CONSTANTS.duplicate:
        handleDuplicate(flow);
        break;
      case FORM_FLOW_ACTIONS_CONSTANTS.privacy:
        handleOpenModal(FORM_FLOW_MODAL_CONSTANTS.formFlowPrivacyModal, flow);
        break;
      case FORM_FLOW_ACTIONS_CONSTANTS.info:
        router.push({ path: flow.links.self });
        break;
      case FORM_FLOW_ACTIONS_CONSTANTS.removeFromFolder:
        handleRemoveFromFolder(flow);
        break;
      case FORM_FLOW_ACTIONS_CONSTANTS.delete:
        handleOpenModal(FORM_FLOW_MODAL_CONSTANTS.flowDeleteModal, flow);
        break;
      default:
        break;
    }
  };

  const resetSelectedRows = () => {
    store.dispatch('flowFolder/setSelectedFlows', []);
    selectedRows.value = [];

    if (tableEl.value?._setupProxy?.selectedRows) {
      tableEl.value._setupProxy.selectedRows = [];
    }
  };

  const handleMove = async ({ selectedFolder }) => {
    try {
      const flowFormIds = selectedRows.value.map((flow) => flow.id);
      store.dispatch('flowFolder/setSelectedFolder', selectedFolder);
      await store.dispatch('flowFolder/addFlowsToFolder', { flowFormIds });
      toast.success(t('flowModule.folders.moveFlowsToFolder.successMessage'));
      resetSelectedRows();
      handleCloseModal();
      await store.dispatch('formFlow/fetchFormFlows');
    } catch (error) {
      toast.error(t('flowModule.folders.moveFlowsToFolder.errorMessage'));
    }
  };

  const handleNewFolder = async () => {
    store.dispatch('flowFolder/setSelectedFlows', selectedRows.value);
    handleCloseModal();
    await nextTick();
    modalShowed.value = FORM_FLOW_MODAL_CONSTANTS.formFlowCreateFolderModal;
  };

  const handleErrorResponse = (err, defaultMessage) => {
    const errors = err.response?.data?.flowFolder?.errors;

    if (errors) {
      Object.values(errors).forEach((value) => toast.error(value[0]));
    } else {
      toast.error(defaultMessage);
    }
  };

  const handleCreateFolder = async ({ name }) => {
    try {
      const flowsLinks = store.getters['formFlow/getFlowsLinks'];
      store.dispatch('flowFolder/setLinks', flowsLinks);
      const {
        data: { flowFolder },
      } = await store.dispatch('flowFolder/createFlowFolder', { name });
      await handleMove({ selectedFolder: flowFolder });
      resetSelectedRows();
    } catch (err) {
      handleErrorResponse(err, t('flowModule.folders.create.errorMessage'));
    }
  };

  return {
    selectedRows,
    tableEl,
    modalShowed,
    handleEdit,
    handleNew,
    handleSuccessMessage,
    handleCloseModal,
    handleDelete,
    handleDisable,
    handleOpenModal,
    handleDuplicate,
    handleClickOption,
    handleMove,
    handleNewFolder,
    handleCreateFolder,
    resetSelectedRows,
  };
}
