export default {
  links: {},
  formFlows: [],
  formFlow: {},
  selectedFormFlow: {},
  dataMappings: [],
  pagination: {},
  loading: true,
  flowPermissions: {},
  hasPendingProcesses: false,
  hasWaitingSecondFormProcesses: false,
  totalItems: 0,
  flowsLinks: {},
  moveFolders: [],
  moveFoldersPagination: {},
};
